<script setup>
import {dateParams} from "@/assets/enums";
import {useStateStore} from "@/assets/state.store";

const state = useStateStore();

const numberComparisonVariants = ['<', '<=', '===', '!=='];

async function setParam(key, value){
  await state.setLoggerParams(key, value);
}

async function toggleUsageForFiltering(type) {
  await state.toggleUsageForFiltering(type);
}

async function toggleValueForFiltering(type, value) {
  await state.toggleValueForFiltering(type, value);
}

async function toggleBooleanValue(type) {
  await state.toggleBooleanValue(type);
}

const recordParamTypeToLang = {
  'array': 'Array',
  'different': 'Different',
  'string': 'String',
  'number': 'Number',
  'object': 'Object',
  'boolean': 'Boolean',
}

</script>

<template>
  <div class='logger-params'>
    <h2>Logger Params</h2>
    <div class="lp_items">
      <div class="lp_item">
        <div class="lpi_title">
          Date:
        </div>
        <div class="lpi_options">
          <div class="lpio_item button second-color text-second-color hover"
               :class="{selected: state.loggerParams.date === dateParams.TODAY}"
               @click="setParam('date', dateParams.TODAY)"
          >
            Today
          </div>
          <div class="lpio_item button second-color text-second-color hover"
               :class="{selected: state.loggerParams.date === dateParams.THIS_MONTH}"
               @click="setParam('date', dateParams.THIS_MONTH)"
          >
            This Month
          </div>
          <div class="lpio_item button second-color text-second-color hover"
               :class="{selected: state.loggerParams.date === dateParams.CUSTOM}"
               @click="setParam('date', dateParams.CUSTOM)"
          >
            Custom
          </div>
        </div>
        <div class="lpi_inputs"
             v-if="state.loggerParams.date === dateParams.CUSTOM"
        >
          <div class="lpii_item">
            <div class="lpii_title">
              From (format yyyy-mm-dd:hh-mm-ss)
            </div>
            <input type="text"
                   class="lpii_input"
                   v-model="state.loggerParams.customDate.from"
                   @change="state.updateLogs"
            />
          </div>
          <div class="lpii_item">
            <div class="lpii_title">
              To (format yyyy-mm-dd:hh-mm-ss)
            </div>
            <input type="text"
                   class="lpii_input"
                   v-model="state.loggerParams.customDate.to"
                   @change="state.updateLogs"
            />
          </div>
        </div>
      </div>
      <div class="lp_item"
           v-if="typeof state.logsInfo?.typesOfRecords === 'object'"
      >
        <div class="lpi_title">
          Filter by properties:
        </div>
        <template
            v-for="recordTypeInfo of state.logRecordTypes"
            :key="recordTypeInfo.key"
        >
          <div class="lpi_first_row">
            <div class="lpio_checkbox"
                 :class="{ selected: state.loggerParams.useToFilter.has(recordTypeInfo.key) }"
                 @click="toggleUsageForFiltering(recordTypeInfo.key)"
            >
            </div>
            <div class="lpi_title">
              {{recordTypeInfo.key}}: {{recordParamTypeToLang[recordTypeInfo.type]}}
            </div>
          </div>
          <template v-if="state.loggerParams.useToFilter.has(recordTypeInfo.key)">
            <div class="lpi_options"
                 v-if="recordTypeInfo.values"
            >
              <div class="lpio_option"
                   v-for="value of recordTypeInfo.values"
                   :key="`${recordTypeInfo.key}_${value}`"
              >
                <div class="lpio_checkbox"
                     :class="{ selected: state.loggerParams.valuesToFilterBy[recordTypeInfo.key].values.includes(value) }"
                     @click="toggleValueForFiltering(recordTypeInfo.key, value)"
                >
                </div>
                <div class="lpio_label">
                  {{value}}
                </div>
              </div>
            </div>
            <div class="lpi_options"
                 v-else-if="recordTypeInfo.type === 'string'"
            >
              <strong>includes:</strong>
              <input type="text"
                     class="lpii_input"
                     v-model="state.loggerParams.valuesToFilterBy[recordTypeInfo.key].mustInclude"
                     @change="state.updateLogs"
              />
            </div>
            <div class="lpi_options"
                 v-else-if="recordTypeInfo.type === 'array'"
            >
              <span style="color: #ff0000; font-weight: bold;">Turned off for now</span>
              <strong>{{recordTypeInfo.key}}.filter(value => {</strong>
              <textarea type="text"
                     class="lpii_array"
                     v-model="state.loggerParams.valuesToFilterBy[recordTypeInfo.key].filterFuncCode"
                     @change="state.updateLogs"
              />
              <br/>
              <strong>})</strong>
            </div>
            <div class="lpi_options"
                 v-else-if="recordTypeInfo.type === 'boolean'"
            >
              <div class="lpi_boolean_switcher"
                   :class="{ valueIsTrue: state.loggerParams.valuesToFilterBy[recordTypeInfo.key].mustBe }"
                   @click="toggleBooleanValue(recordTypeInfo.key)"
              >
                {{state.loggerParams.valuesToFilterBy[recordTypeInfo.key].mustBe}}
              </div>
            </div>
            <div class="lpi_options"
                 v-else-if="recordTypeInfo.type === 'number'"
            >
              <div class="lpio_option">
                <input type="number"
                       class="lpii_number_comparison"
                       v-model="state.loggerParams.valuesToFilterBy[recordTypeInfo.key].firstNumber"
                       @change="state.updateLogs"
                />
                <select
                    v-model="state.loggerParams.valuesToFilterBy[recordTypeInfo.key].firstComparisonType"
                    @change="state.updateLogs"
                    :disabled="!state.loggerParams.valuesToFilterBy[recordTypeInfo.key].firstNumber"
                >
                  <option
                      v-for="variant of numberComparisonVariants"
                      :key="variant"
                      :value="variant"
                  >
                    {{variant}}
                  </option>
                </select>
                <span><strong>i</strong></span>
                <select
                    v-model="state.loggerParams.valuesToFilterBy[recordTypeInfo.key].secondComparisonType"
                    @change="state.updateLogs"
                    :disabled="!state.loggerParams.valuesToFilterBy[recordTypeInfo.key].secondNumber"
                >
                  <option
                      v-for="variant of numberComparisonVariants"
                      :key="variant"
                      :value="variant"
                  >
                    {{variant}}
                  </option>
                </select>
                <input type="number"
                       class="lpii_number_comparison"
                       v-model="state.loggerParams.valuesToFilterBy[recordTypeInfo.key].secondNumber"
                       @change="state.updateLogs"
                />
              </div>
            </div>
            <div class="lpi_options"
                 v-else
            >
              <strong>No Functionality for now;</strong>
            </div>
          </template>
        </template>
      </div>
      <div class="lp_item"
           v-if="typeof state.logsInfo?.typesOfRecords === 'object'"
      >
        <div class="lpi_title">
          Properties to display:
        </div>
        <div class="lpi_first_row">
          <div class="lpio_checkbox"
               :class="{ selected: state.paramsToDisplay.has('id') }"
               @click="state.toggleParamToDisplay('id')"
          >
          </div>
          <div class="lpi_title">
            id
          </div>
        </div>
        <div class="lpi_first_row">
          <div class="lpio_checkbox"
               :class="{ selected: state.paramsToDisplay.has('date') }"
               @click="state.toggleParamToDisplay('date')"
          >
          </div>
          <div class="lpi_title">
            date
          </div>
        </div>
        <template
            v-for="recordTypeInfo of state.logRecordTypes"
            :key="recordTypeInfo.key"
        >
          <div class="lpi_first_row">
            <div class="lpio_checkbox"
                 :class="{ selected: state.paramsToDisplay.has(recordTypeInfo.key) }"
                 @click="state.toggleParamToDisplay(recordTypeInfo.key)"
            >
            </div>
            <div class="lpi_title">
              {{recordTypeInfo.key}}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .lpii_array{
    padding-left: 10px;
  }

  .lpi_boolean{
    display: flex;
  }

  .lpii_number_comparison{
    font-size: 1.2em;
    width: 50px;
  }

  .lpi_boolean_switcher{
    cursor: pointer;
    font-size: 1.2em;
    padding: 6px 10px;
    background: #ff0000;
  }

  .lpi_boolean_switcher.valueIsTrue{
    background: #00ff00;
  }

  .logger-params{
    padding: 10px;
    width: 400px;
    background-color: #a6a6a6;
  }

  .lpio_option{
    display: flex;
    gap: 6px;
  }

  .lpi_title{
    font-size: 1.4em;
    font-weight: bold;
  }

  .lp_items{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px;
  }

  .lpi_first_row{
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .lp_item{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .lpi_options{
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: start;
  }

  .lpio_item.button{
    cursor: pointer;
    padding: 6px 15px;
  }

  .lpio_item.button.selected,
  .lpio_item.button.selected:hover{
    background-color: #2e68bf;
    cursor: default;
  }

  .lpi_inputs{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .lpii_item{
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .lpii_title{
    font-size: 1.2em;
  }

  .lpii_input{
    padding: 4px 8px;
    font-size: 1.1em;
  }
</style>