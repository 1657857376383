import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import BackendClass from '@/assets/backend.class';
import ProjectsClass from '@/assets/projects.class';
import UsersClass from "@/assets/users.class";
import LoggerClass from "@/assets/logger.class";

async function startApp(){
  const pinia = createPinia()
  const app = createApp(App)

  app.use(pinia)

  ProjectsClass.connectStateStore();
  BackendClass.connectStateStore();
  UsersClass.connectStateStore()
  LoggerClass.connectStateStore();
  await BackendClass.init();

  app.mount('#app')
}

startApp();