<script setup>
  import { useStateStore } from '@/assets/state.store';
  import BackendClass from '@/assets/backend.class';

  const stateStore = useStateStore();
  const selectProject = () => {
    stateStore.selectProject(stateStore.selectedProject);
  };
  function logOut() {
    BackendClass.logout();
  }
  // eslint-disable-next-line no-undef
  const props = defineProps(['routeName']);
</script>

<template>
  <div class='top_bar second-color'>
    <div class="tb_left">
      <div class='tb_project'>
        <div class='tbp_title text-second-color'>
          Project:
        </div>
        <select class='tbp_select color-on-second'
                v-model='stateStore.selectedProject'
                @change='selectProject'
        >
          <option v-for='name of stateStore.projectNames'
                  :key='name'
                  :value='name'
          >
            {{name}}
          </option>
        </select>
      </div>
      <div class="tbl_version text-second-color">
        Version: {{stateStore.api.version}}
      </div>
    </div>
    <span class='tb_title text-second-color'>{{props.routeName}}</span>
    <div class='tb_user'>
      <div class='tbu_name text-second-color'>
        {{stateStore.username}}
      </div>
      <div class='tb_log_out'
           @click='logOut'
      >

      </div>
    </div>
  </div>
</template>

<style scoped>
  .top_bar{
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 800px;
    height: 60px;
    padding: 10px;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
  }

  .tbl_version{
    display: flex;
    align-items: center;
  }

  .tb_left{
    display: flex;
    gap: 20px;
  }

  .tb_title{
    font-size: 1.5em;
  }

  .tb_project{
    font-size: 1.2em;
  }
  .tbp_select{
    padding: 2px;
  }

  .tb_user{
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .tb_log_out{
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: #ff0000;
    cursor: pointer;
    mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='11' fill='none'%3E%3Cpath fill='%23000' d='M0 1.718 1.047.671 9.66 9.283 8.612 10.33z'/%3E%3Cpath fill='%23000' d='m.004 9.286 1.04 1.04 8.612-8.612-1.04-1.04z'/%3E%3C/svg%3E") center center/contain no-repeat;
    -webkit-mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='11' fill='none'%3E%3Cpath fill='%23000' d='M0 1.718 1.047.671 9.66 9.283 8.612 10.33z'/%3E%3Cpath fill='%23000' d='m.004 9.286 1.04 1.04 8.612-8.612-1.04-1.04z'/%3E%3C/svg%3E") center center/contain no-repeat;
  }
</style>