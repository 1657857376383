import { useStateStore } from '@/assets/state.store';
import backendClass from '@/assets/backend.class';


class ProjectsClass {
  projects;
  stateStore;
  isInited;
  constructor() {
    this.projects = [];
    this.stateStore = null;
    this.isInited = false;
  }

  async init(){
    this.projects = await this.getProjects();
    this.isInited = true;
  }

  async getProjects(){
    const response = await backendClass.get('api/projects');
    return await response.json();
  }

  async addProject(projectName){
    const response = await backendClass.post('api/projects/create', {name: projectName});
    const data = await response.json();

    if(data.status === 'success'){
      this.projects.push({name: projectName});
      this.stateStore.updateProjects();
    }else{
      console.error(data?.message || 'Error adding project');
    }
  }

  async deleteProject(projectName){
    const response = await backendClass.delete('api/projects/delete', {name: projectName});
    const data = await response.json();

    if(data.status === 'success'){
      this.projects = this.projects.filter(project => project.name !== projectName);
      this.stateStore.updateProjects();
    }else{
      console.error(data?.message || 'Error deleting project');
    }
  }

  connectStateStore(){
    this.stateStore = useStateStore();
  }

  get projectNames(){
    return this.projects.map(project => project.name);
  }

}

export default new ProjectsClass();