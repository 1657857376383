<script setup>
import { useStateStore } from '@/assets/state.store';
import { onMounted } from 'vue';


const state = useStateStore();
onMounted(async () => {
  await state.updateLogs();
})

function handleScroll(e) {
  const container = e.target;
  if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
    state.getLogsNextPage();
  }
}
</script>

<template>
  <div class='l_list second-color scrolable'
       ref="scrollContainer"
       @scroll="handleScroll"
       :class="{loading: state.loadingLogs}"
  >
    <template v-if='state.logs.length'>
      <template
          v-for='log of state.logsToDisplay'
          :key="`log_${log.id}`"
      >
        <div class="ll_item_container">
          <div class="lpio_checkbox"
               :class="{ selected: !state.collapsedLogs.has(log.id) }"
               @click="state.toggleLogCollapsed(log.id)"
          >
          </div>
          <div class='ll_item text-second-color'
               :data-type="log.type"
          >
            <template
                v-if="!state.collapsedLogs.has(log.id)"
            >
              <span class='lli_key_value'
                    v-for='entry in log.entries'
                    :key='entry[0]'
              >
                {{entry[0]}}: {{entry[1]}}
              </span>
            </template>
            <span v-else>
              {{log.entries[0][0]}}: {{log.entries[0][1]}}
            </span>
          </div>
        </div>
      </template>
    </template>
    <template v-else>
      <div class='ll_item text-second-color'>
        No logs
      </div>
    </template>
  </div>
</template>

<style scoped>
  .lpio_checkbox{
    transform: translateY(7px);
  }

  .l_list{
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
    padding: 30px;
    width: 100%;
    max-width: 1200px;
    max-height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    gap: 40px;
    font-size: 1.5em;
    position: relative;
  }

  .l_list.loading:before{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.51);
  }

  .ll_item_container{
    display: flex;
    gap: 10px;
  }

  .ll_item{
    display: flex;
    flex-direction: column;
    gap: 6px;
    column-gap: 25px;
    flex-wrap: wrap;
    color: #d37464;
  }

  .ll_item[data-type="info"]{
    color: #dcdcdc;
  }

  .ll_item[data-type="process"]{
    color: #36a8dc;
  }

  .ll_item[data-type="warning"]{
    color: #ffb600;
  }

  .ll_item[data-type="success"]{
    color: #a2ff6b;
  }

  .ll_item[data-type="debug"]{
    color: #eaa6ff;
  }

  .ll_item[data-type="error"]{
    color: #ff0000;
  }

  .ll_item[data-type="system_error"]{
    color: #c50000;
  }
</style>