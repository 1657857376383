<script setup>
  import backendClass from '@/assets/backend.class';
  import { ref } from 'vue';

  const username = ref('');
  const password = ref('');
  const falsePassword = ref(false);
  async function login(){
    falsePassword.value = !await backendClass.login(username.value, password.value);
    username.value = '';
    password.value = '';
  }
</script>

<template>
  <div class="login_form">
    <div class='component_title'>
      <h1>Login</h1>
    </div>
    <div class='login_input'>
      <span>Username: </span>
      <input type='text'
             placeholder='Username'
             v-model='username'
      />
    </div>
    <div class='login_input'>
      <span>Password: </span>
      <input type='password'
             placeholder='Password'
             v-model='password'
             @keydown.enter="login"
      />
    </div>
    <div v-if='falsePassword' class='login_false_password'>
      <h2>Wrong username or password</h2>
    </div>
    <button class='login_send second-color text-second-color hover'
            @click='login'
    >Login</button>
  </div>
</template>

<style scoped>
  .login_false_password{
    color: #ff0000;
  }

  .login_form{
    position: relative;
    left: 0;
    right: 0;
    width: 420px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 14vh auto auto;
    gap: 10px;
  }

  .component_title{
    text-align: center;
  }

  .login_input{
    font-size: 1.4em;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
  }

  .login_input span{

  }

  .login_input input{
    font-size: 1em;
    padding: 5px 8px;
  }

  .login_send{
    font-size: 1.4em;
    letter-spacing: 0.4em;
    font-weight: bold;
    cursor: pointer;
    border: none;
    padding: 10px 5px;
  }
</style>