<script setup>
import ProjectsClass from '@/assets/projects.class';
import { ref } from 'vue';
import { useStateStore } from '@/assets/state.store';

const stateStore = useStateStore();
const newProjectName = ref('');

async function deleteProject(projName){
  await ProjectsClass.deleteProject(projName);
}
async function createProject(){
  await ProjectsClass.addProject(newProjectName.value);
  newProjectName.value = '';
}
</script>

<template>
  <div class='proj_list'>
    <div class='pl_item first-color-a'
      v-for='projName in stateStore.projectNames'
      v-bind:key='projName'
    >
      <div class='pli_title'>
        {{projName}}
      </div>
      <div class='pli_delete'
           @click='deleteProject(projName)'
      >
        x
      </div>

    </div>
  </div>
  <div class='proj_add'>
    <div class='pa_title first-color-a'>
      Add a new project
    </div>
    <input
      type='text'
      v-model='newProjectName'
      placeholder='Project name'/>
    <div class='pa_create'
         @click='createProject'
    >
      Create
    </div>
  </div>
</template>

<style scoped>
  .proj_list{
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: start;
  }

  .pl_item{
    font-size: 1.5em;
    display: flex;
    gap: 50px;
  }

  .pli_title{
    cursor: pointer;
  }

  .pli_delete{
    cursor: pointer;
  }

  .proj_add{
    margin-top: 20px;
  }
</style>