import Config from '@/assets/config';
import { useStateStore } from '@/assets/state.store';
import StorageClass from '@/assets/storage.class';

class BackendClass {
  url;
  authToken;
  state;
  constructor() {
    this.url = Config.apiUrl;
    this.authToken = '';
    setInterval(() => {
      this.checkToken();
    }, 1000 * 10);
  }

  async init(){
    const token = StorageClass.getItem('auth-token');
    const username = StorageClass.getItem('user-name');
    if(token && username){
      this.authToken = token;
      const isValueToken = await this.checkToken();
      if(!isValueToken){
        this.authToken = '';
        this.state.setUsername('');
        this.state.switchLoggedStatus(false);
        return;
      }
      this.state.setUsername(username);
      this.state.switchLoggedStatus(true);
    }
    this.state.api.version = await this.getApiVersion();
  }

  async getApiVersion(){
    const response = await this.get('api/status', {}, false).catch((e) => {
      console.error('BackendClass | getApiVersion | error: ', e);
      return '';
    });
    const data = await response.json();
    if(!data || !data.version){
      return '';
    }
    return data.version
  }

  connectStateStore(){
    this.state = useStateStore();
  }
  async login(username, password){
    const response = await this.post('api/auth/login', {username, password}, false);
    if(response.ok){
      const data = await response.json();
      this.authToken = data.token;
      this.state.switchLoggedStatus(true);
      StorageClass.setItem('user-name', username);
      StorageClass.setItem('auth-token', this.authToken);
      this.state.setUsername(username);
      return true;
    }
    this.state.setUsername('');
    this.state.switchLoggedStatus(false);
    return false;
  }
  logout(){
    this.authToken = '';
    StorageClass.deleteItem('auth-token');
    StorageClass.deleteItem('user-name');
    this.state.setUsername('');
    this.state.switchLoggedStatus(false);
  }

  async checkToken(){
    if(!this.authToken){
      return false;
    }
    const response = await this.get('api/auth/test-token', {}).catch((e) => {
      console.error('BackendClass | checkToken | error: ', e);
      this.authToken = '';
      this.state.setUsername('');
      this.state.switchLoggedStatus(false);
      return false;
    });
    if(!response || !response.status || response.status === 401){
      this.authToken = '';
      this.state.setUsername('');
      this.state.switchLoggedStatus(false);
      return false;
    }
    return true;
  }
  async getRoles(){
    const response = await this.get('api/auth/roles').catch((e) => {
      throw new Error('BackendClass | getRoles | error: ' + e.message);
    });
    const data = await response.json();
    if(!data || !data.data || !data.data.roles){
      return [];
    }
    return data.data.roles;
  }

  post(url, data = {}, isAddToken = true){
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };
    if(isAddToken){
      config.headers['Authorization'] = 'Bearer ' + this.authToken;
    }
    return fetch(this.url + url, config);
  }
  get(url, queryData = {}, isAddToken = true){
    let query = '';
    if(queryData && typeof queryData === 'object'){
      for(const key in queryData){
        if(query.length){
          query += '&'
        }else{
          query += '?';
        }
        query += `${key}=${queryData[key]}`;
      }
    }
    const config = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
    if(isAddToken){
      config.headers['Authorization'] = 'Bearer ' + this.authToken;
    }
    return fetch(`${this.url}${url}${query}`, config);
  }
  delete(url, data = {}, isAddToken = true){
    const config = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };
    if(isAddToken){
      config.headers['Authorization'] = 'Bearer ' + this.authToken;
    }
    return fetch(this.url + url, config);
  }
}

export default new BackendClass();