<script setup>
import TopBar from '@/components/TopBar.vue';
import SideMenu from '@/components/SideMenu.vue';
import {computed, onMounted, ref} from 'vue';
import NotFound from '@/components/NotFound.vue';
import Projects from '@/components/ProjectsController.vue';
import Users from '@/components/UsersController.vue';
import LoggerMain from '@/components/LoggerMain.vue';
import {useStateStore} from "@/assets/state.store";

const routes = {
  '/': LoggerMain,
  'projects': Projects,
  'users': Users,
}
const routeNames = {
  '/': 'Main',
  'projects': 'Projects',
  'users': 'Users',
}
const state = useStateStore();
const privateRoutes = new Set(['projects', 'users']);
const currentPath = ref(window.location.hash)

window.addEventListener('hashchange', () => {
  if(privateRoutes.has(window.location.hash.slice(1)) && !state.roles.has('admin')){
    window.location.hash = '#';
  }
  currentPath.value = window.location.hash
})

const currentView = computed(() => {
  return routes[currentPath.value.slice(1) || '/'] || NotFound
})
const currentRouteName = computed(() => {
  return routeNames[currentPath.value.slice(1) || '/'] || 'Not Found'
})
const componentProps = computed(() => {
  return {};
})
onMounted(() => {
  if(privateRoutes.has(window.location.hash.slice(1)) && !state.roles.has('admin')){
    window.location.hash = '#';
  }
})
function componentEmit(
  // emitType, ...args
){}
</script>

<template>
  <SideMenu />
  <TopBar :route-name='currentRouteName'/>
  <div class='main'>
    <div class='component'>
      <component :is="currentView" @component-emit='componentEmit' v-bind='componentProps' />
    </div>
  </div>
</template>

<style scoped>
  .main{
    margin: 100px 40px;
    position: relative;
    left: 0;
    right: 0;
    max-height: calc(100vh - 200px);
  }

  .component {
    width: 100%;
  }
</style>