<script setup>
import { computed, ref } from 'vue';
import { useStateStore } from '@/assets/state.store';
  const isOpen = ref(false);
  const state = useStateStore()
  const menuItems = computed(() => {
    const items = [
      ['LOGGER', '/#'],
    ];

    if(state.roles.has('admin')){
      items.push(['PROJECTS', '/#projects']);
      items.push(['USERS', '/#users']);
    }

    return items;
  });
  function switchOpen (){
    isOpen.value = !isOpen.value;
  }
  function onSwitchPage (){
    isOpen.value = false;
  }
</script>

<template>
  <div class='sm_closer'
       @click='switchOpen'
       :class='{isOpen: isOpen}'
  >
    <div class='smc_rect'
         v-for='i in [1,2,3]'
         v-bind:key='i'
         :class='{
            "main-side-color": !isOpen,
            "second-side-color": isOpen
         }'
    ></div>
  </div>
  <div class='side_menu second-color'
       :class='{isOpen: isOpen}'
  >
    <div class='sm_links'
      v-if='isOpen'
    >
      <a class='sm_item second-color-a'
         v-for='[iName, iUrl] in menuItems'
         @click='onSwitchPage'
         v-bind:key='iName'
         :href='iUrl'
      >{{iName}}</a>
    </div>
  </div>
</template>

<style scoped>
  .side_menu{
    position: fixed;
    left: 0;
    top: 0;
    width: 0px;
    height: 100%;
    z-index: 120;
    padding: 100px 0px;
    -webkit-transition: width 0.1s 0s ease-in-out;
    -moz-transition: width 0.1s 0s ease-in-out;
    -o-transition: width 0.1s 0s ease-in-out;
    transition: width 0.1s 0s ease-in-out;
  }
  .side_menu.isOpen{
    width: 350px;
    padding: 100px 20px;
  }

  .sm_links{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .sm_item{
    font-size: 1.5em;
    cursor: pointer;
  }

  .sm_closer{
    cursor: pointer;
    width: 50px;
    height: 44px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    padding: 10px 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: space-around;
    -webkit-transition: width 0.1s 0s ease-in-out;
    -moz-transition: width 0.1s 0s ease-in-out;
    -o-transition: width 0.1s 0s ease-in-out;
    transition: width 0.1s 0s ease-in-out;
  }

  .sm_closer.isOpen{
    width: 350px;
  }

  .smc_rect{
    height: 4px;
    -webkit-transition: background-color 0.1s 0s ease-in-out;
    -moz-transition: background-color 0.1s 0s ease-in-out;
    -o-transition: background-color 0.1s 0s ease-in-out;
    transition: background-color 0.1s 0s ease-in-out;
  }
</style>