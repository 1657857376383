<script setup>
import { useStateStore } from '@/assets/state.store';
import {onBeforeMount, reactive, ref} from "vue";
import usersClass from "@/assets/users.class";

const stateStore = useStateStore();
const isBlockUsers = ref(false);
const newUserUsername = ref('');
const newUserPassword = ref('');
const newUserCreateErrors = ref([]);
const newUserHighlightInputErrors = ref({
  username: false,
  password: false,
})

const tokensOfServiceAccounts = reactive({});
const copiedTokens = reactive({});

const newServiceUserUsername = ref('');
const newServiceUserIp = ref('');
const newServiceUserCreateErrors = ref([]);
const newServiceUserHighlightInputErrors = ref({
  username: false,
  ip: false,
})
const roleToTitle = {
  'admin': "Admin",
  'developer': "Developer",
  'user': "User",
}

async function switchRoleForUser(username, role){
  isBlockUsers.value = true;
  await usersClass.switchRole(username, role);
  isBlockUsers.value = false;
}

async function deleteUser(username){
  isBlockUsers.value = true;
  await usersClass.deleteUser(username);
  isBlockUsers.value = false;
}
async function deleteServiceAccount(username){
  isBlockUsers.value = true;
  await usersClass.deleteServiceUser(username);
  isBlockUsers.value = false;
}

async function createNewUser(){
  newUserCreateErrors.value.length = 0;
  newUserHighlightInputErrors.value.username = false;
  newUserHighlightInputErrors.value.password = false;

  if(!newUserUsername.value){
    newUserCreateErrors.value.push('Username is required');
    newUserHighlightInputErrors.value.username = true;
  } else if(newUserUsername.value === 'admin'){
    newUserCreateErrors.value.push('Username "admin" is not allowed');
    newUserHighlightInputErrors.value.username = true;
  } if(!newUserPassword.value){
    newUserCreateErrors.value.push('Password is required');
    newUserHighlightInputErrors.value.password = true;
  }
  if(newUserCreateErrors.value.length){
    return;
  }


  isBlockUsers.value = true;
  await usersClass.createUser(newUserUsername.value, newUserPassword.value);
  newUserPassword.value = '';
  newUserUsername.value = '';
  isBlockUsers.value = false;
}
async function createServiceAccount(){
  newServiceUserCreateErrors.value.length = 0;
  newServiceUserHighlightInputErrors.value.username = false;
  newServiceUserHighlightInputErrors.value.ip = false;

  if(!newServiceUserUsername.value){
    newServiceUserCreateErrors.value.push('Username is required');
    newServiceUserHighlightInputErrors.value.username = true;
  } else if(newServiceUserUsername.value === 'admin'){
    newServiceUserCreateErrors.value.push('Username "admin" is not allowed');
    newServiceUserHighlightInputErrors.value.username = true;
  } if(!newServiceUserIp.value){
    newServiceUserCreateErrors.value.push('Ip is required');
    newServiceUserHighlightInputErrors.value.ip = true;
  }
  if(newServiceUserCreateErrors.value.length){
    return;
  }


  isBlockUsers.value = true;
  const token = await usersClass.createServiceUser(newServiceUserUsername.value, newServiceUserIp.value);
  if(token){
    tokensOfServiceAccounts[newServiceUserUsername.value] = token;
  }

  newServiceUserUsername.value = '';
  newServiceUserIp.value = '';
  isBlockUsers.value = false;
}
function copyToken(username){
  navigator.clipboard.writeText(tokensOfServiceAccounts[username]);
  copiedTokens[username] = true;
  setTimeout(() => {
    delete copiedTokens[username];
  }, 2000);
}

onBeforeMount(() => {
  stateStore.getUsers();
  stateStore.getServiceUsers();
})
</script>

<template>
  <div>
    <h1>Users Controller</h1>
    <div class="users_default">
      <div class="users_type_title">
        Users
      </div>
      <div class="users_list">
        <div class="ul_item"
             v-for="user of stateStore.usersList"
             :key="user.username"
        >
          <div class="uiri_delete"
               @click="deleteUser(user.username)"
          ></div>
          <div class="uli_title">
            {{user.username}}
          </div>
          <div class="uli_roles">
            <div class="ulir_item"
                 v-for="role of stateStore.existingRoles"
                 :key="role"
                 :class="{
                 blocked: isBlockUsers,
                 selected: user.roles?.includes(role),
               }"
            >
              <div class="uiri_title">
                {{roleToTitle[role]}}
              </div>
              <div class="uiri_checkbox"
                   :class="{
                  disabled: role === 'user' || role === 'admin'
                }"
                   @click="switchRoleForUser(user.username, role)"
              >

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="users_add_new">
        <div class="uan_title">
          Create new user:
        </div>
        <div class="uan_errors"
             v-if="newUserCreateErrors.length"
        >
          {{newUserCreateErrors.join('; ')}}
        </div>
        <div class="uan_input">
          <div class="uani_title">
            Username
          </div>
          <input class="uani_input"
                 :class="{highlight_error: newUserHighlightInputErrors.username}"
                 type="text"
                 v-model="newUserUsername"
          />
        </div>
        <div class="uan_input">
          <div class="uani_title">
            Password
          </div>
          <input class="uani_input"
                 :class="{highlight_error: newUserHighlightInputErrors.password}"
                 type="password"
                 v-model="newUserPassword"
          />
        </div>
        <div class="uan_create"
             @click="createNewUser"
        >
          Create
        </div>
      </div>
    </div>
    <div class="users_service">
      <div class="users_type_title">
        Service Accounts
      </div>
      <div class="users_list">
        <div class="ul_item"
             v-for="user of stateStore.serviceUsersList"
             :key="user.username"
        >
          <div class="uiri_delete"
               @click="deleteServiceAccount(user.username)"
          ></div>
          <div class="uli_title">
            {{user.username}}
          </div>
          <div class="uli_token"
               v-if="tokensOfServiceAccounts[user.username]"
               :class="{copied: copiedTokens[user.username]}"
               @click="copyToken(user.username)"
          >

          </div>
        </div>
      </div>
      <div class="users_add_new">
        <div class="uan_title">
          Create new service account:
        </div>
        <div class="uan_errors"
             v-if="newServiceUserCreateErrors.length"
        >
          {{newServiceUserCreateErrors.join('; ')}}
        </div>
        <div class="uan_input">
          <div class="uani_title">
            Username
          </div>
          <input class="uani_input"
                 :class="{highlight_error: newServiceUserHighlightInputErrors.username}"
                 type="text"
                 v-model="newServiceUserUsername"
          />
        </div>
        <div class="uan_input">
          <div class="uani_title">
            Ip
          </div>
          <input class="uani_input"
                 :class="{highlight_error: newServiceUserHighlightInputErrors.password}"
                 type="text"
                 v-model="newServiceUserIp"
          />
        </div>
        <div class="uan_create"
             @click="createServiceAccount"
        >
          Create
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .users_type_title{

  }

  .users_service{
    margin-top: 20px;
  }

  .users_list{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .uli_token{
    display: inline-block;
    width: 19px;
    height: 24px;
    background-color: #333333;
    cursor: pointer;
    mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='28' fill='none'%3E%3Cpath stroke='%23000' stroke-width='2' d='M7 1h14v20H7z'/%3E%3Cpath stroke='%23000' stroke-width='2' d='M5 5.5H1.5v21h15V23'/%3E%3C/svg%3E") center center/contain no-repeat;
    -webkit-mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='28' fill='none'%3E%3Cpath stroke='%23000' stroke-width='2' d='M7 1h14v20H7z'/%3E%3Cpath stroke='%23000' stroke-width='2' d='M5 5.5H1.5v21h15V23'/%3E%3C/svg%3E") center center/contain no-repeat;
    transform: translateY(12px);
    -webkit-transition: background-color 0.3s 0s ease-in;
    -moz-transition: background-color 0.3s 0s ease-in;
    -o-transition: background-color 0.3s 0s ease-in;
    transition: background-color 0.3s 0s ease-in;
  }

  .uli_token:hover{
    background-color: #5a5a5a;
  }

  .uli_token.copied,
  .uli_token.copied:hover{
    background-color: #1a82bc;
    -webkit-transition: background-color 0s 0s ease-in;
    -moz-transition: background-color 0s 0s ease-in;
    -o-transition: background-color 0s 0s ease-in;
    transition: background-color 0s 0s ease-in;
  }

  .uiri_delete{
    display: inline-block;
    width: 15px;
    height: 16px;
    background-color: #333333;
    cursor: pointer;
    mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='16' fill='none'%3E%3Cg fill='%23959595' clip-path='url(%23a)'%3E%3Cpath d='m10.506 5.516-1.252-.045-.272 7.415 1.253.045.271-7.415Zm-2.422-.023H6.83v7.416h1.254V5.493Zm-2.153 7.392-.27-7.415-1.254.046.272 7.415 1.252-.046Z'/%3E%3Cpath d='M0 2.402v1.253h1.306l1.036 11.773c.028.324.3.572.624.572h8.96a.627.627 0 0 0 .625-.572l1.036-11.773h1.327V2.402H0Zm11.353 12.345H3.54L2.564 3.655h9.765l-.976 11.092Z'/%3E%3Cpath d='M9.504 0H5.41c-.576 0-1.044.469-1.044 1.044V3.03H5.62V1.253h3.676V3.03h1.253V1.044C10.548.468 10.08 0 9.504 0Z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h15v16H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E") center center/contain no-repeat;
    -webkit-mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='16' fill='none'%3E%3Cg fill='%23959595' clip-path='url(%23a)'%3E%3Cpath d='m10.506 5.516-1.252-.045-.272 7.415 1.253.045.271-7.415Zm-2.422-.023H6.83v7.416h1.254V5.493Zm-2.153 7.392-.27-7.415-1.254.046.272 7.415 1.252-.046Z'/%3E%3Cpath d='M0 2.402v1.253h1.306l1.036 11.773c.028.324.3.572.624.572h8.96a.627.627 0 0 0 .625-.572l1.036-11.773h1.327V2.402H0Zm11.353 12.345H3.54L2.564 3.655h9.765l-.976 11.092Z'/%3E%3Cpath d='M9.504 0H5.41c-.576 0-1.044.469-1.044 1.044V3.03H5.62V1.253h3.676V3.03h1.253V1.044C10.548.468 10.08 0 9.504 0Z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h15v16H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E") center center/contain no-repeat;
    transform: translateY(12px);
  }

  .uiri_delete:hover{
    background: #ff0000;
  }

  .ul_item{
    font-size: 2em;
    display: flex;
    gap: 20px;
  }

  .uli_title{

  }

  .uli_roles{

  }

  .ulir_item{
    display: flex;
    align-items: center;
    gap: 25px;
    position: relative;
  }

  .ulir_item.blocked:before{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 153;
    background: #00000020;
  }

  .ulir_item.selected{

  }

  .uiri_title{

  }

  .ulir_item.selected .uiri_checkbox{
    background: #2a749c;
  }

  .ulir_item.selected .uiri_checkbox:before{
    position: absolute;
    content: '';
    display: inline-block;
    width: 13px;
    height: 10px;
    background: #ffffff;
    mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='10' fill='none'%3E%3Cpath fill='%23333' d='M4.136 7.91 1.034 4.776 0 5.821 4.136 10 13 1.045 11.966 0l-7.83 7.91Z'/%3E%3C/svg%3E") center center/contain no-repeat;
    -webkit-mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='10' fill='none'%3E%3Cpath fill='%23333' d='M4.136 7.91 1.034 4.776 0 5.821 4.136 10 13 1.045 11.966 0l-7.83 7.91Z'/%3E%3C/svg%3E") center center/contain no-repeat;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }


  .uiri_checkbox{
    width: 20px;
    height: 20px;
    border: 2px solid #333;
    cursor: pointer;
    background: #fff;
    position: relative;
  }

  .uiri_checkbox.disabled,
  .ulir_item.selected .uiri_checkbox.disabled{
    cursor: default;
    background: #c9c9c9;
    border-color: #8a8a8a;
  }

  .users_add_new{
    margin-top: 10px;
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .uan_title{
    font-size: 2em;
    color: #333;
  }

  .uan_errors{
    margin: 10px 0px;
    color: #ff0000;
    max-width: 400px;
    font-weight: bold;
    font-size: 1.25em;
  }

  .uan_input{
    display: flex;
    flex-direction: column;
    width: 300px;
    gap: 5px;
    margin: 10px 0;
  }

  .uani_title{
    font-size: 1.25em;
  }

  .uani_input{
    font-size: 1.25em;
    padding: 2px 5px;
  }

  .uan_create{
    cursor: pointer;
    font-size: 1.75em;
    background: #333333;
    color: #fff;
    padding: 8px 25px;
    text-align: center;
  }

  .uan_create:hover{
    background: #5a5a5a;
  }
</style>
