class StorageClass {
  storage;
  constructor() {
    this.storage = window.sessionStorage;
  }

  getItem(key) {
    let result = null;
    try{
      result = JSON.parse(this.storage.getItem(key) || 'null');
    }catch (e) {
      console.error('Error getting item from storage', e);
    }

    return result;
  }

  setItem(key, value) {
    this.storage.setItem(key, JSON.stringify(value));
  }

  deleteItem(key) {
    this.storage.removeItem(key);
  }
}

export default new StorageClass();