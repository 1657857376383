import backendClass from '@/assets/backend.class';
import {useStateStore} from "@/assets/state.store";
import {dateParams} from "@/assets/enums";

class LoggerClass{
  state;
  page;
  constructor() {
    this.page = 0;
  }

  connectStateStore(){
    this.state = useStateStore();
  }

  getNewLogs(projectName) {
    this.page = 0;
    return this.getLogs(projectName, this.page);
  }

  getNextPageOfLogs(projectName) {
    return this.getLogs(projectName, ++this.page);
  }

  async getLogs(projectName, page){
    let result = [];
    if(!projectName || projectName === 'no-project'){
      console.log('Logger | getLogs | no project name');
      return result;
    }
    try{
      const queryData = {
        projectName,
        date: this.state.loggerParams.date,
        page,
        valuesToFilterBy: this.state.loggerParams.valuesToFilterBy,
      };
      if(queryData.date === dateParams.CUSTOM){
        queryData.customDateFrom = this.state.loggerParams.customDate.from;
        queryData.customDateTo = this.state.loggerParams.customDate.to;
      }
      const response = await backendClass.post('api/records/get', queryData);
      const body = await response.json();
      if(body?.status !== 'success'){
        throw new Error(`Status: ${body?.status}`);
      }
      if(!body?.data?.records || typeof body.data.records !== 'object'){
        throw new Error('No records returned')
      }
      result = body.data.records;
    }catch (e) {
      console.error(`Logger | getLogs | error: ${e}`);
    }
    return result;
  }
  async getLogsInfo(projectName){
    let result = [];
    if(!projectName || projectName === 'no-project'){
      console.log('Logger | getLogs | no project name');
      return result;
    }
    try{
      const queryData = {projectName};
      const response = await backendClass.get('api/records/info', queryData);
      const body = await response.json();
      if(body?.status !== 'success'){
        throw new Error(`Status: ${body?.status}`);
      }
      if(!body?.data){
        throw new Error('No logs info returned');
      }
      result = body.data;
    }catch (e) {
      console.error(`Logger | getLogs | error: ${e}`);
    }
    return result;
  }
}

export default new LoggerClass();