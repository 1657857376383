<script setup>
import { useStateStore } from '@/assets/state.store';
import LoginForm from '@/components/LoginForm.vue';
import MainPage from '@/components/MainPage.vue';

const state = useStateStore();
</script>

<template>
  <template v-if='state.isLoggedIn'>
    <MainPage />
  </template>
  <template v-else>
    <LoginForm />
  </template>
</template>

<style>
    body{
      background: #f8d281;
      font-family: "Roboto Condensed", sans-serif;
    }

    *{
      box-sizing: border-box;
      margin: 0;
    }

    select:focus,
    select:focus-visible{
      outline: none;
      border-radius: 0;
    }


    .lpio_checkbox {
      width: 18px;
      height: 18px;
      min-width: 18px;
      min-height: 18px;
      border: 1px solid #333333;
      background: #fff;
      position: relative;
      cursor: pointer;
    }

    .lpio_checkbox.selected:before{
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #333333;
      mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' fill='none'%3E%3Cpath fill='%23000' d='M0 1.048 1.048 0l8.611 8.612L8.612 9.66z'/%3E%3Cpath fill='%23000' d='m.004 8.615 1.04 1.04 8.612-8.611-1.04-1.04z'/%3E%3C/svg%3E") center center/contain no-repeat;
      -webkit-mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' fill='none'%3E%3Cpath fill='%23000' d='M0 1.048 1.048 0l8.611 8.612L8.612 9.66z'/%3E%3Cpath fill='%23000' d='m.004 8.615 1.04 1.04 8.612-8.611-1.04-1.04z'/%3E%3C/svg%3E") center center/contain no-repeat;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
    }

    .main-side-color{
      background: #000000;
    }
    .second-side-color{
      background: #ffffff;
    }
    .main-color{
      background: #f8d281;
    }
    .second-color{
      background: #111b2a;
    }

    .second-color.hover:hover{
      background: #162947;
    }

    .color-on-second{
      background: #2e7569;
      color: #ffffff;
    }
    .text-main-color{
      color: #333333;
    }
    .text-second-color{
      color: #ffffff;
    }
    .first-color-a{
      color: #333333;
      text-decoration: none;
    }
    .first-color-a:hover{
      color: #000000;
    }
    .second-color-a{
      color: #ffffff;
      text-decoration: none;
    }
    .second-color-a:hover{
      color: #bbbbbb;
    }

    .scrolable::-webkit-scrollbar {
      width: 20px;
    }

    .scrolable::-webkit-scrollbar-track {
      background: url(@/assets/matrix.png);
      background-size: 400px;
      background-position-x: 60px;
      filter: grayscale(100%);
    }

    .scrolable::-webkit-scrollbar-thumb {
      background: rgba(130, 224, 120, 0.44);
    }
</style>