import {useStateStore} from "@/assets/state.store";
import backendClass from "@/assets/backend.class";

class UsersClass {
    constructor() {
        this.state = null;
    }
    connectStateStore(){
        this.state = useStateStore();
    }
    async switchRole(username, role) {
        if(role === 'user' || !this.state.roles.has('admin') || role === 'admin'){
            return;
        }

        const user = this.state.usersList.find(user => user.username === username);
        if(!user || !user.roles){
            console.error('switchRole | user not found');
            return;
        }

        let response;
        if(user.roles.includes(role)) {
            response = await backendClass.post('api/users/delete-role', {username, role});
        } else {
            response = await backendClass.post('api/users/add-role', {username, role});
        }
        const data = await response.json();
        if(data?.status === 'success'){
            await this.state.getUsers();
        }
    }
    async deleteUser(username) {
        if(!this.state.roles.has('admin')){
            return;
        }
        const response = await backendClass.delete('api/users/delete', {username});
        const data = await response.json();
        if(data?.status === 'success'){
            await this.state.getUsers();
        }
    }
    async createUser(username, password) {
        if(!this.state.roles.has('admin')){
            return;
        }
        const response = await backendClass.post('api/users/create', {username, password});
        const data = await response.json();
        if(data?.status === 'success'){
            await this.state.getUsers();
        }
    }
    async deleteServiceUser(username) {
        if(!this.state.roles.has('admin')){
            return;
        }
        const response = await backendClass.delete('api/service-users/delete', {username});
        const data = await response.json();
        if(data?.status === 'success'){
            await this.state.getServiceUsers();
        }
    }
    async createServiceUser(username, ip) {
        if(!this.state.roles.has('admin')){
            return;
        }
        const response = await backendClass.post('api/service-users/create', {username, ip});
        const data = await response.json();
        if(!data?.status || !data?.data?.token){
            console.error('createServiceUser | error');
            return '';
        }
        await this.state.getServiceUsers();
        return data?.data?.token;
    }
}

export default new UsersClass();