import { defineStore } from 'pinia';
import StorageClass from '@/assets/storage.class';
import ProjectsClass from '@/assets/projects.class';
import LoggerClass from '@/assets/logger.class';
import BackendClass from '@/assets/backend.class';
import backendClass from "@/assets/backend.class";
import {dateParams} from "@/assets/enums";
// import axios from 'axios';
const useStateStore = defineStore('state', {
  state: () => ({
    selectedProject: 'no-project',
    projectNames: [],
    projects: [],
    logs: [],
    usersList: [],
    serviceUsersList: [],
    roles: new Set(),
    existingRoles: new Set(),
    isLoggedIn: false,
    username: '',
    loadingLogs: false,
    logsInfo: {
      firstRecordDate: '',
      lastRecordDate: '',
    },
    collapsedLogs: new Set(),
    lastPageOfLogsDownloaded: false,
    loggerParams: {
      date: dateParams.TODAY,
      customDate: {
        from: '',
        to: ''
      },
      useToFilter: new Set(),
      // key: {typeOfFiltering}
      valuesToFilterBy: {},
    },
    paramsToDisplay: new Set(),
    api: {
      version: '',
    },
  }),
  actions: {
    selectProject(projectName) {
      if(!projectName){
        StorageClass.deleteItem('selected-project');
        this.selectedProject = 'no-project';
        this.updateLogs();
        return;
      }
      this.selectedProject = projectName;
      StorageClass.setItem('selected-project', projectName);
      this.paramsToDisplay = new Set();
      this.updateLogs();
    },
    setUsername(username){
      this.username = username;
    },
    updateProjects(){
      this.projects = ProjectsClass.projects;
      this.projectNames = ProjectsClass.projectNames;
    },
    async getUsers(){
      const response = await backendClass.get('api/users/list');
      const jsonResponse = await response.json();
      if(jsonResponse?.status !== 'success' || !jsonResponse?.data?.users){
        console.error('StateStore getUsers error');
        return;
      }
      this.usersList = jsonResponse.data.users
    },
    async getServiceUsers(){
      console.log('getServiceUsers');
      const response = await backendClass.get('api/service-users/list');
      const jsonResponse = await response.json();
      if(jsonResponse?.status !== 'success' || !jsonResponse?.data?.serviceUsers){
        console.error('StateStore getServiceUsers error');
        return;
      }
      this.serviceUsersList = jsonResponse.data.serviceUsers
    },
    async getExistingRoles(){
      const response = await backendClass.get('api/users/get-existing-roles');
      const jsonResponse = await response.json();
      if(jsonResponse?.status !== 'success' || !jsonResponse?.data?.roles){
        console.error('StateStore getExistingRoles error');
      }
      this.existingRoles = new Set(jsonResponse.data.roles);
    },
    async switchLoggedStatus(status){
      if(this.isLoggedIn === status){
        return;
      }
      this.isLoggedIn = status;
      if(status){
        await this.init();
      }
    },
    async updateLogs(){
      this.lastPageOfLogsDownloaded = false;
      this.loadingLogs = true;
      const info = await Promise.all([LoggerClass.getLogsInfo(this.selectedProject), LoggerClass.getNewLogs(this.selectedProject)]);
      this.logsInfo = info[0];
      if (!this.paramsToDisplay.size && this.logsInfo.typesOfRecords) {
        this.paramsToDisplay = new Set(Object.keys(this.logsInfo.typesOfRecords));
        this.paramsToDisplay.add('date');
      }
      this.collapsedLogs = new Set();
      this.logs = info[1].length ? [info[1]] : [];
      this.loadingLogs = false;
    },
    async getLogsNextPage() {
      if(this.lastPageOfLogsDownloaded){
        return;
      }
      this.loadingLogs = true;
      const logPage = await LoggerClass.getNextPageOfLogs(this.selectedProject);
      if(logPage.length){
        this.logs.push(logPage);
      } else{
        this.lastPageOfLogsDownloaded = true;
      }
      this.loadingLogs = false;
    },
    async init(){
      const roles = await BackendClass.getRoles();
      if(!roles || !roles.length){
        this.switchLoggedStatus(false);
        return;
      }
      this.roles = new Set(roles);
      if(!ProjectsClass.isInited){
        await ProjectsClass.init();
      }
      await this.updateProjects();
      const selectedProject = StorageClass.getItem('selected-project');
      if(selectedProject && this.projectNames.includes(selectedProject)){
        this.selectedProject = selectedProject;
      }
      await this.getExistingRoles();
      await this.updateLogs();
    },
    async toggleUsageForFiltering(type){
      if (this.loggerParams.useToFilter.has(type)) {
        this.loggerParams.useToFilter.delete(type)
        if (this.loggerParams.valuesToFilterBy[type]) {
          delete this.loggerParams.valuesToFilterBy[type]
        }
      } else {
        this.loggerParams.useToFilter.add(type);
        const values = this.logsInfo.typesOfRecords[type].stringValues || this.logsInfo.typesOfRecords[type].numberValues;
        if (values && values !== 'any') {
          this.loggerParams.valuesToFilterBy[type] = {
            typeOfFiltering: 'byValues',
            values: Array.from(values),
          };
        } else if (this.logsInfo.typesOfRecords[type].type === 'string') {
            this.loggerParams.valuesToFilterBy[type] = {
                typeOfFiltering: 'includes',
                mustInclude: '',
            };
        } else if (this.logsInfo.typesOfRecords[type].type === 'array') {
            this.loggerParams.valuesToFilterBy[type] = {
                typeOfFiltering: 'filterFunc',
                filterFuncCode: 'return true;',
            };
        } else if (this.logsInfo.typesOfRecords[type].type === 'boolean') {
            this.loggerParams.valuesToFilterBy[type] = {
                typeOfFiltering: 'isTrue',
                mustBe: true,
            };
        } else if (this.logsInfo.typesOfRecords[type].type === 'number') {
            this.loggerParams.valuesToFilterBy[type] = {
                typeOfFiltering: 'numberComparison',
                firstNumber: '',
                secondNumber: '',
                firstComparisonType: '<',
                secondComparisonType: '<',
            };
        }
      }
      await this.updateLogs();
    },
    async toggleBooleanValue(type) {
      this.loggerParams.valuesToFilterBy[type].mustBe = !this.loggerParams.valuesToFilterBy[type].mustBe;
        await this.updateLogs();
    },
    async toggleValueForFiltering(type, value){
      const index = this.loggerParams.valuesToFilterBy[type].values.findIndex((v) => v === value);
        if(index === -1){
            this.loggerParams.valuesToFilterBy[type].values.push(value);
        } else {
            this.loggerParams.valuesToFilterBy[type].values.splice(index, 1);
        }
      await this.updateLogs();
    },
    async setLoggerParams(key, value){
      this.loggerParams[key] = value;
      if(key === 'date' && value === dateParams.CUSTOM){
        if(!this.loggerParams.customDate.from && this.logsInfo.firstRecordDate) {
          this.loggerParams.customDate.from = this.logsInfo.firstRecordDate;
        }
        if(!this.loggerParams.customDate.to && this.logsInfo.lastRecordDate) {
          this.loggerParams.customDate.to = this.logsInfo.lastRecordDate;
        }
      }
      await this.updateLogs();
    },
    toggleLogCollapsed(logId) {
        if(this.collapsedLogs.has(logId)){
            this.collapsedLogs.delete(logId);
        } else {
            this.collapsedLogs.add(logId);
        }
    },
    toggleParamToDisplay(param){
        if(this.paramsToDisplay.has(param)){
            this.paramsToDisplay.delete(param);
        } else {
            this.paramsToDisplay.add(param);
        }
    }
  },
  getters: {
    logRecordTypes(){
      let result = [];

      try {
        for (const key in this.logsInfo.typesOfRecords) {
          const values = this.logsInfo.typesOfRecords[key].stringValues || this.logsInfo.typesOfRecords[key].numberValues;
          result.push({
            key,
            type: this.logsInfo.typesOfRecords[key].type,
            values: values !== 'any' ? values : null,
          });
        }
      } catch (e) {
        console.error(e);
      }

      return result;
    },
    logsToDisplay(){
      let result = [];

      for (const logPage of this.logs) {
        for (const log of logPage) {
          let logToAdd = [];
          for (const lKey in log) {
            if( !this.paramsToDisplay.has(lKey) ) {
              continue;
            }
            logToAdd.push([lKey, log[lKey]]);
          }
          result.push({id: log.id, entries: logToAdd, type: log.type})
        }
      }

      return result;
    },
  }
});

export {useStateStore};